(function($) {
    /** Gestion de l'inversion des couleurs du menu lors de l'ouverture du menu principal */
    var classname = document.getElementsByClassName("menu-item--lvl0");
    var toggleMenu = function() {
        if($(".menu-component--lvl0.active").length){
            $("header").addClass("header--show-menu");
        }else{
            $("header").removeClass("header--show-menu");
        }
    };
    for (var i = 0; i < classname.length; i++) {
        var item = classname[i];
        var eventId = item.getAttribute("data-toggle-id");
        classname[i].addEventListener('menu-toggle-event-' + eventId, toggleMenu, false);
    }
    var closeMenu = function() {
        if($(".menu-component--lvl0").length){
            $(".menu-component--lvl0").removeClass("active");
        }
        $("header").removeClass("header--show-menu");
    };
    body.addEventListener('close-all', closeMenu, false);
})(jQuery.noConflict());
